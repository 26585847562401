import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const CorreoCorporativo = lazy(() => import('sections/hero/CorreoCorporativo.js'));
const BotonCorreo = lazy(() => import('sections/services/BotonCorreo.js'));
const CorreoPlanes = lazy(() => import('sections/services/CorreoPlanes.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {

    

    return (
      <div>
        <Helmet>
          <title>Correo Corporativo: Soluciones profesionales de Email</title>
          <meta name="description" content="Descubre las soluciones profesionales de correo corporativo. Gestiona tus comunicaciones empresariales de forma eficiente y segura con nuestras herramientas." />
        </Helmet>
        <Layout>
          <CorreoCorporativo />          
          <CorreoPlanes />          
          <Llamenos />
          <BotonCorreo />          
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`